<template>
  <div
    class="accordion-gallery-card"
    :class="{'--active': active}"
    @click="_click"
  >
    <nuxt-img
      :src="String(item.imag_id)"
      :alt="item.title"
      width="847px"
      height="420px"
      class="accordion-gallery-card__image"
    />
    <div
      v-if="active && !hideContent"
      class="accordion-gallery-card__body"
    >
      <div class="accordion-gallery-card__name h3">
        {{ item.title }}
      </div>

      <div
        v-if="item.link"
        class="accordion-gallery-card__link"
      >
        <nuxt-link :to="item.link">
          Подробнее
        </nuxt-link>
      </div>

      <div class="accordion-gallery-card__navigation">
        <div
          class="--prev"
          @click="_prevSlide"
        >
          <img
            src="~/assets/img/svg/common/arrow.svg"
            alt="prev"
          />
        </div>
        <div
          class="--next"
          @click="_nextSlide"
        >
          <img
            src="~/assets/img/svg/common/arrow.svg"
            alt="next"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  item: {
    type: Object,
    default: {}
  },
  active: {
    type: Boolean,
    default: false
  },
  hideContent: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(['prevSlide', 'nextSlide', 'click']);

const _click = () => {
  emit('click');
};
const _prevSlide = (event: any) => {
  event.stopPropagation();
  emit('prevSlide');
};
const _nextSlide = (event: any) => {
  event.stopPropagation();
  emit('nextSlide');
};
</script>

<style lang="scss" scoped>
.accordion-gallery-card {
	position: relative;
	overflow: hidden;
	width: 100%;
}

.accordion-gallery-card__image {
	position: relative;
	width: 100%;
	height: 420px;
	top: 0;
	left: 0;
	object-fit: cover;
	border-radius: 30px;
}

.accordion-gallery-card__body {
	margin-top: 24px;
	position: relative;
	min-width: var(--agb-width-card-open);
}

.accordion-gallery-card__name {

}

.accordion-gallery-card__link {
	display: flex;
	align-items: center;
	margin-top: 16px;
	justify-content: flex-start;

	a {
		color: #989898;
		font-size: 16px;
		line-height: 100%;
		font-weight: 600;
		text-transform: uppercase;
		border-bottom: 1px solid;
		opacity: 0.5;
		transition: all 0.2s;

		&:hover {
			border-bottom-color: transparent;
		}
	}
}

.accordion-gallery-card__navigation {
	display: flex;
	position: absolute;
	top: 0;
	right: 0;

	& > * {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 7px;
		width: 28px;
		height: 28px;
		background-color: #F4F3F1;
		cursor: pointer;
		margin-left: 7px;
		padding: 5px;
		box-sizing: border-box;

		&:first-child {
			margin-left: 0;
		}
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	.--next {
		img {
			transform: rotate(180deg);
		}
	}
}
</style>
